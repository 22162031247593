<template>
  <div class="outer">
    <div class="hl-header">
      <div class="hl-search">
        <div class="hl-title">控制配置 ({{ infor.deviceTypeName }})</div>
        <div>
          <el-button
            v-if="chuangjian"
            type="primary"
            @click="addIdType(0, null)"
            class="addIdType"
            >+ 控制配置</el-button
          >
          <el-button type="primary" @click="addIdType1" class="addIdType1"
            >返回上一级</el-button
          >
        </div>
      </div>
    </div>
    <div class="hl-content">
      <!-- <div class="mokuai">控制配置列表</div> -->
      <el-table
        class="hl-table"
        :data="tableData"
        height="90%"
        :header-cell-style="{ background: '#F4F5F9', color: '#000000' }"
      >
        <el-table-column label="主控设备类型">
          <template slot-scope="scope">
            <span>{{ scope.row.controlDidName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="主控设备别名">
          <template slot-scope="scope">
            <span>{{ scope.row.controlName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="主控设别位号">
          <template slot-scope="scope">
            <span>{{ scope.row.placeNoA }}</span>
          </template>
        </el-table-column>
        <el-table-column label="主控设备号">
          <template slot-scope="scope">
            <span>{{ scope.row.deviceNoA }}</span>
          </template>
        </el-table-column>
        <el-table-column label="被控设备类型">
          <template slot-scope="scope">
            <span>{{ scope.row.controlledDidName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="被控设备别名">
          <template slot-scope="scope">
            <span>{{ scope.row.controlledName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="被控设备位号">
          <template slot-scope="scope">
            <span>{{ scope.row.placeNoB }}</span>
          </template>
        </el-table-column>
        <el-table-column label="被控设备号">
          <template slot-scope="scope">
            <span>{{ scope.row.deviceNoB }}</span>
          </template>
        </el-table-column>
        <el-table-column label="触发状态">
          <template slot-scope="scope">
            <span v-if="scope.row.controlDeviceTrigger == 'Valid'">触发</span>
            <span v-else>不触发</span>
          </template>
        </el-table-column>
        <el-table-column label="控制模式">
          <template slot-scope="scope">
            <span>{{ scope.row.controlMode }}</span>
          </template>
        </el-table-column>
        <el-table-column label="相同与设备">
          <template slot-scope="scope">
            <span>{{ scope.row.sameDeviceName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="延时">
          <template slot-scope="scope">
            <span>{{ scope.row.delayTime }} S</span>
          </template>
        </el-table-column>

        <el-table-column label="操作顺序">
          <template slot-scope="scope">
            <!-- <span>{{ scope.row.operateOrder }} -->
            <el-input
              style="width: 100px"
              ref="paramCode"
              v-model="scope.row.operateOrder"
              maxlength="20"
              @input="operateChange(scope.row)"
            ></el-input>

            <!-- </span> -->
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <div class="cz">
              <div class="qidong">
                <el-button
                  class="cz-button"
                  size="mini"
                  v-if="scope.row.controlMode == '设置值'"
                  @click="edit(scope.row)"
                  >参数配置</el-button
                >
                <el-button
                  style="color: red"
                  type="text"
                  v-if="shanchu"
                  @click="del(scope.row)"
                  >删除</el-button
                >
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          background
          @size-change="onPaginationSize"
          @current-change="onPaginationIndex"
          :current-page="condition.offset"
          :page-size="condition.num"
          layout=" prev, pager, next, jumper,total"
          :total="totolNums"
        ></el-pagination>
      </div>
    </div>
    <!-- 删除 -->
    <el-dialog title="提示" :visible.sync="deleteVisible" width="30%">
      <el-input
        placeholder="请输入删除指示"
        v-model="todelete"
        style="width: 100%"
        maxlength="10"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteSure">确 定</el-button>
      </span>
    </el-dialog>
    <addModify ref="addModify" />
    <disableModify ref="disableModify" />
  </div>
</template>
<script>
// import { querySelectAllUser } from "@/api/api2.js";
import { getRequest, postRequest, deleteRequest } from "@/api/api.js";
import addModify from "@/views/contrl/addModify.vue";
import disableModify from "@/views/contrl/disableModify.vue";
export default {
  data() {
    return {
      tableData: [],
      list: [],
      hotelList: [],
      roomStatusList: [],
      input: "",
      status: 0,
      versionCode: "",
      versionName: "",
      statusRemark: "",
      totolNums: 1,
      condition: {
        hotelId: null,
        offset: 1,
        num: 9,
      },
      chuangjian: false,
      xiugai: false,
      shanchu: false,
      deleteVisible: false,
      row: {},
      todelete: "",
    };
  },
  components: {
    addModify,
    disableModify,
  },
  created() {
    this.infor = this.$route.query;
    if (!this.power.checkedpower("0304012")) {
      // 在PMS管理系统里面-获取权限
      this.$router.push("power");
    }
    // 创建
    this.chuangjian = this.power.checkedpower("0304009");
    // 修改
    this.xiugai = this.power.checkedpower("0304010");
    // 删除，禁用
    this.shanchu = this.power.checkedpower("0304011");
    this.getFounderList();
    this.getHotelList();
    this.getRoomStatusList();
  },
  methods: {
    getHotelList() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      let parms = {
        offset: 1,
        num: 10000,
      };
      postRequest("selectAllHotel", parms).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          this.hotelList = res.data.list;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    getRoomStatusList() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      postRequest("selectAllSysRoomStatus", {}).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          this.roomStatusList = res.data;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    // 获取创始人列表
    getFounderList() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      let parms = {
        controlDevice: this.infor.id,
        roomType: this.infor.roomType,
        offset: this.condition.offset,
        limit: this.condition.num,
      };
      postRequest("getAllDeviceControl", parms).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          this.tableData = res.data;
          this.totolNums = res.total;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    // 删除
    del(row) {
      this.deleteVisible = true;
      this.todelete = "";
      this.row = row;
    },
    deleteSure() {
      if (this.todelete != "yes") {
        this.$message({
          duration: 5000,
          message: "请输入指示！",
          center: true,
        });
        return;
      }
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      deleteRequest("deleteHotelDeviceControl?id=" + this.row.id).then(
        (res) => {
          this.$nextTick(() => {
            loadingInstance.close();
          });
          if (res.status == 0) {
            this.$message({
              duration: 5000,
              message: "删除成功!",
              type: "success",
            });
            this.getFounderList();
            this.deleteVisible = false;
          } else {
            this.$message({
              duration: 5000,
              message: res.message,
              center: true,
            });
          }
        }
      );
    },
    // 修改操作顺序
    operateChange(row) {
      console.log(11, row);
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      let parms = {
        id: row.id,
        operateOrder: row.operateOrder,
      };
      postRequest("updateDeviceControlOrder", parms).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          this.$message({
            duration: 5000,
            message: "已修改操作顺序 ！",
            center: true,
            type: "success",
          });
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    search() {},
    // 新增
    addIdType() {
      let state = "创建";
      let roomType = this.infor.roomType;
      let controlDid = this.infor.controlDid;
      this.$refs.addModify.show(state, this.infor);
    },
    // 修改
    edit(row) {
      let state = "参数配置";
      console.log(1, row);
      this.$refs.disableModify.show(state, row);
    },
    // 每页的数据数
    onPaginationSize(Sizeval) {
      this.condition.num = Sizeval;
      this.getFounderList();
    },
    // 页数
    onPaginationIndex(Indexpage) {
      this.condition.offset = Indexpage;
      this.getFounderList();
    },
    addIdType1() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.outer {
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.button {
  width: 60px;
  height: 30px;
  /* background:rgb(163, 197, 248) ; */
  background-color: cornflowerblue;
  color: white;
  border: none;
}
.button2 {
  background-color: white;
  border: 1px solid #999;
  color: #666;
}
.hl-header {
  width: 100%;
  height: 60px;
  display: flex;
}
.hl-search {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  position: relative;
}
.hl-title {
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
  color: rgba(0, 0, 0, 0.85);
}
.el-card {
  position: relative;
}
.addIdType {
  width: 136px;
  height: 40px;
  background: #387dff;
  border-radius: 4px;
}
.hl-search .span {
  width: 40px;
  height: 30px;
  font-size: 14px;
  position: absolute;
  right: 10px;
  top: 5px;
  font-size: 14px;
  border: none;
}
.hl-content {
  flex: 1;
  background-color: #fff;
  padding: 21px 32px;
  position: relative;
  /* overflow: scroll; */
}
.mokuai {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  opacity: 0.85;
  margin-bottom: 21px;
}
.hl-table {
  /* background: red; */
  overflow: auto;
}
.cz-button {
  width: 74px;
  height: 28px;
  background: #6ec543;
  opacity: 1;
  border-radius: 4px;
  color: #fff;
}
.qidong {
  width: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 24px;
}
.line {
  display: inline-block;
  height: 14px;
  border: 1px solid #e8ecef;
}
.cz {
  display: flex;
}
.block {
  position: absolute;
  bottom: 10px;
  right: 32px;
}
.qiyong {
  display: inline-block;
  width: 6px;
  height: 6px;
  background: #387dff;
  border-radius: 50%;
  margin-right: 6px;
}
.jinyong {
  display: inline-block;
  width: 6px;
  height: 6px;
  background: #f5222d;
  border-radius: 50%;
  margin-right: 6px;
}
.zt > div {
  display: flex;
  align-items: center;
}
.addIdType2 {
  width: 100px;
  height: 40px;
  background: #387dff;
  border-radius: 4px;
}
.addIdType1 {
  width: 136px;
  height: 40px;
  color: #387dff;
  background: #d7e4fc;
  border-radius: 4px;
}
.dialog-footer > .el-button {
  width: 60px;
  height: 30px;
}
.el-table {
  /* height: 90%; */
}
</style>