<template>
  <div>
    <el-dialog
      :title="title + '控制配置'"
      :visible.sync="showing"
      width="96%"
      min-height="500px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="hide"
    >
      <div v-loading="loading">
        <!-- 大盒子 -->
        <div class="big_box">
          <!-- 左边盒子 -->
          <div class="left_box">
            <div class="header_box">
              <div class="jurisdiction">可选被控设备列表</div>
              <!-- <div class="select" @click="checkAllBtn()">全选</div> -->
            </div>
            <div class="content">
              <el-table
                ref="table"
                :data="mode"
                tooltip-effect="dark"
                style="width: 100%"
                @selection-change="handleSelectionChange"
                @select="dialogCheck"
                @row-click="rowClick"
              >
                <el-table-column type="selection"> </el-table-column>
                <el-table-column label="设备别名">
                  <template slot-scope="scope">{{
                    scope.row.deviceName
                  }}</template></el-table-column
                >
                <el-table-column label="设备类型" width="120">
                  <template slot-scope="scope">{{
                    scope.row.deviceTypeName
                  }}</template>
                </el-table-column>

                <el-table-column label="位号">
                  <template slot-scope="scope">{{
                    scope.row.placeNo
                  }}</template></el-table-column
                >
                <el-table-column label="设备号">
                  <template slot-scope="scope">{{
                    scope.row.deviceNo
                  }}</template></el-table-column
                >
              </el-table>
            </div>
          </div>
          <div class="change">
            <!-- <el-button type="primary">》》</el-button> -->
            <img src="../../img/right.png" alt="" class="img" @click="right" />

            <img src="../../img/left.png" alt="" class="img" @click="left" />
          </div>
          <!-- 右边盒子 -->
          <div class="right_box">
            <div class="header_box">
              <div class="jurisdiction">已选列表</div>
              <!-- <div class="select" @click="checkAllBtn1()">全选</div> -->
            </div>
            <div class="content">
              <!-- <el-checkbox-group class="bottom" v-model="checkedList">
                <el-checkbox
                  v-for="(item, index2) in choosepower"
                  :label="item.name"
                  :key="index2"
                  @change="chooseCheck"
                  >{{ item.name }}</el-checkbox
                >
              </el-checkbox-group> -->
              <el-table
                ref="multipleTable"
                :data="choosepower"
                tooltip-effect="dark"
                style="width: 100%"
                @selection-change="handleChoose"
                @row-click="rowClicks"
              >
                <el-table-column type="selection"> </el-table-column>
                <el-table-column label="设备别名">
                  <template slot-scope="scope">{{
                    scope.row.deviceName
                  }}</template></el-table-column
                >
                <el-table-column label="设备类型" width="120">
                  <template slot-scope="scope">{{
                    scope.row.deviceTypeName
                  }}</template>
                </el-table-column>

                <el-table-column label="位号">
                  <template slot-scope="scope">{{
                    scope.row.placeNo
                  }}</template></el-table-column
                >
                <el-table-column label="设备号">
                  <template slot-scope="scope">{{
                    scope.row.deviceNo
                  }}</template></el-table-column
                >
                <el-table-column label="序号">
                  <template slot-scope="scope">{{
                    scope.row.index
                  }}</template></el-table-column
                >
              </el-table>
            </div>
          </div>
          <div class="set_box">
            <div class="header_box">
              <div class="jurisdiction">
                配置参数
                <span v-if="deviceName != null">({{ deviceName }})</span>
              </div>
              <!-- <div class="select" @click="checkAllBtn()">全选</div> -->
            </div>
            <div class="content">
              <el-table
                ref="canshuTable"
                :data="canshuSet"
                tooltip-effect="dark"
                style="width: 100%"
              >
                <el-table-column label="参数名称" width="150">
                  <template slot-scope="scope">
                    <div>
                      {{ scope.row.paramName }}
                    </div>
                  </template></el-table-column
                >
                <el-table-column label="设定值">
                  <template slot-scope="scope">
                    <div v-if="scope.row.values.length == 1">
                      <div
                        v-for="(item, index) in scope.row.values"
                        :key="index"
                      >
                        <el-input
                          v-model="scope.row.valueCode"
                          @input="setInput(scope.row)"
                          placeholder="设定值"
                        ></el-input>
                        <!-- <div
                          class="theColor"
                          style="z-index: 9999"
                        >
                          <colorPicker
                            v-model="color"
                            @change="sendColor(colorLight)"
                          />
                        </div> -->
                      </div>
                    </div>
                    <div v-else-if="scope.row.values.length > 1">
                      <el-select
                        class="text"
                        style="width: 100%"
                        @change="setSelect(scope.row)"
                        v-model="scope.row.valueCode"
                        placeholder="请选择房间状态"
                      >
                        <el-option
                          v-for="item in scope.row.values"
                          :key="item.valueCode"
                          :label="item.valueDesc"
                          :value="item.valueCode"
                        >
                        </el-option>
                      </el-select>
                    </div>
                    <div v-else>
                      <el-radio-group
                        v-model="scope.row.controlDeviceTrigger"
                        v-if="scope.row.paramName == '触发状态'"
                        @change="
                          handleChang(scope.row.controlDeviceTrigger, scope.row)
                        "
                      >
                        <el-radio label="Valid">触发</el-radio>
                        <el-radio label="Invalid">不触发</el-radio>
                      </el-radio-group>
                      <el-select
                        class="text"
                        style="width: 100%"
                        @change="modeChange(scope.row)"
                        v-model="scope.row.controlledMode"
                        v-if="scope.row.paramName == '控制模式'"
                        placeholder="请选择控制模式"
                      >
                        <el-option
                          v-for="item in controlMode"
                          :key="item.name"
                          :label="item.name"
                          :value="item.code"
                        >
                        </el-option>
                      </el-select>
                      <el-input
                        v-if="scope.row.paramName == '延时'"
                        v-model="scope.row.delayTime"
                        placeholder="延时"
                      ></el-input>
                    </div> </template
                ></el-table-column>
              </el-table>
            </div>
          </div>
        </div>
        <div class="saveBox">
          <el-button type="primary" @click="save" class="addIdType"
            >确 定</el-button
          >
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="参数配置确定"
      :visible.sync="setVisible"
      width="60%"
      class="setSure"
    >
      <el-table :data="dest" tooltip-effect="dark" style="width: 100%">
        <el-table-column label="被控设备别名">
          <template slot-scope="scope">{{
            scope.row.deviceName
          }}</template></el-table-column
        >
        <el-table-column label="设备类型">
          <template slot-scope="scope">{{
            scope.row.deviceTypeName
          }}</template></el-table-column
        >
        <el-table-column label="参数">
          <template slot-scope="scope">
            <div class="paramName">
              <div v-for="(item, index) in scope.row.list" :key="index">
                <div class="leftName">{{ item.paramName }}:</div>
                <div>
                  <div v-if="item.valueDesc">{{ item.valueDesc }}</div>
                  <div v-if="item.paramName == '延时'">
                    {{ item.delayTime }}(s)
                  </div>
                  <div v-if="item.paramName == '控制模式'">
                    <!-- 设置值 -->
                    <span v-if="item.controlledMode == 'SetValue'">设置值</span>
                    <span v-if="item.controlledMode == 'Equal'"
                      >相同与 ({{ item.sameName }})</span
                    >
                    <span v-if="item.controlledMode == 'Toggle'">转换</span>
                  </div>
                  <div v-if="item.paramName == '触发状态'">
                    <span v-if="item.controlDeviceTrigger == 'Valid'"
                      >触发</span
                    >
                    <span v-if="item.controlDeviceTrigger == 'Invalid'"
                      >不触发</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </template></el-table-column
        >
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="setVisible = false" class="setFalse"
          >取 消</el-button
        >
        <el-button
          type="primary"
          :disabled="flag2 != '0'"
          @click="setSure('设置值')"
          >保存</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      title="相同与"
      :visible.sync="sameVisible"
      width="50%"
      class="setSure"
    >
      <el-table
        :data="equal"
        tooltip-effect="dark"
        style="width: 100%"
        ref="powerTable"
        @selection-change="handleChoosepower"
        @select="dialogPower"
        @row-click="rowClick2"
      >
        <el-table-column type="selection"> </el-table-column>
        <el-table-column label="设备类型名称">
          <template slot-scope="scope">{{
            scope.row.deviceTypeName
          }}</template></el-table-column
        >
        <el-table-column label="设备别名">
          <template slot-scope="scope">{{
            scope.row.deviceName
          }}</template></el-table-column
        >
        <el-table-column label="设备号">
          <template slot-scope="scope">{{
            scope.row.deviceNo
          }}</template></el-table-column
        >
        <el-table-column label="位号">
          <template slot-scope="scope">{{
            scope.row.placeNo
          }}</template></el-table-column
        >
      </el-table>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="sameVisible = false" class="setFalse"
          >取 消</el-button
        > -->
        <el-button type="primary" @click="sameSetSure">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import { createInsertUser, updateInsertUser } from "@/api/api2.js";
import { getRequest, postRequest } from "@/api/api.js";

export default {
  data() {
    return {
      //审核弹窗
      title: "",
      showing: false,
      loading: false,
      bedisabled: false,
      sameVisible: false,
      hotel: true,
      index: null,
      changeStat: {},
      //表单
      formInline: {
        arStatus: 0,
        arModule: 1,
        arSpeed: 1,
        arTemperature: "26",
      },
      hotelList: [],
      roomStatusList: [],
      checkedList: [],
      firstList: [],
      canshuSet: [],
      powerTableList: [],
      roomType: null,
      controlDid: null,
      deviceTypeName: null,
      deviceName: null,

      totelSetList: [],
      dest: [],
      equal: [],
      mode: [
        {
          code: 0,
          name: 1,
        },
        {
          code: 1,
          name: 2,
        },
      ],
      flag: null,
      radio: null,
      choosepower: [],
      codeIndex: null,
      operateOrder: null,
      main: {},
      other: {},
      // 选择左侧list
      chooseList: [],
      Params: [],
      controlMode: [],
      sameList: [],
      setVisible: false,
      showSetValue: false,
      showSure: 0,
      flag2: 0,
      //表单验证
      rules: {
        hotelId: [
          {
            required: true,
            message: "请选择酒店",
            trigger: "blur",
          },
        ],
        roomStatus: [
          {
            required: true,
            message: "请输入房间状态",
            trigger: "blur",
          },
        ],
        arTemperature: [
          {
            required: true,
            message: "请输入空调温度",
            trigger: "blur",
          },
        ],
        setvalue: "Valid",
      },
    };
  },
  props: ["state"],
  methods: {
    hide() {
      this.fileListUpload = [];
      this.showing = false;
      this.$parent.getFounderList();
      // this.$refs.formInline.resetFields(); //清空表单
    },
    show(item, infor) {
      this.$store.state.ParamsList = [];
      this.setvalue = "Valid";
      this.formInline = {};
      console.log(123, infor);
      this.main = infor;
      this.Params = [];
      this.roomType = infor.roomType;
      this.controlDid = infor.id;
      let hotelInfor = JSON.parse(window.localStorage.getItem("hotelInfor"));
      this.formInline.hotelId = Number(hotelInfor.hotelId);
      this.getHotelList();
      this.getRoomStatusList();
      this.getControlled();
      this.getControlMode();
      // 获取相同与设备
      this.deviceList();
      this.choosepower = [];
      this.title = item;
      this.showing = true;
      this.bedisabled = false;
      if (item == "编辑") {
        this.bedisabled = true;
        this.formInline = JSON.parse(JSON.stringify(val));
        this.formInline.arStatus = Number(this.formInline.arStatus);
        this.formInline.arModule = Number(this.formInline.arModule);
        this.formInline.arSpeed = Number(this.formInline.arSpeed);
        // this.formInline.roomStatus = Number(this.formInline.roomStatus);
        console.log(this.formInline);
      }
    },
    deviceList() {
      let parms = {
        roomType: this.roomType,
      };
      postRequest("selectEqualDeviceList", parms).then((res) => {
        if (res.status == 200) {
          this.equal = res.data;
          console.log("equal", this.equal);
        } else {
          this.$message({
            message: res.message,
            center: true,
            duration: 5000,
          });
        }
      });
    },
    getControlled() {
      let parms = {
        roomType: this.roomType,
        controlDid: this.controlDid,
      };
      postRequest("getCanBeControlled", parms).then((res) => {
        if (res.status == 200) {
          this.mode = res.data;
        } else {
          this.$message({
            message: res.message,
            center: true,
            duration: 5000,
          });
        }
      });
    },
    getControlMode() {
      getRequest("selectAllSysControlMode", {}).then((res) => {
        if (res.status == 200) {
          this.controlMode = res.data;
          console.log(255, this.controlMode);
        } else {
          this.$message({
            message: res.message,
            center: true,
          });
        }
      });
    },
    getHotelList() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      let parms = {
        offset: 1,
        num: 10000,
      };
      postRequest("selectAllHotel", parms).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          this.hotelList = res.data.list;
        } else {
          this.$message({
            message: res.message,
            center: true,
            duration: 5000,
          });
        }
      });
    },
    getRoomStatusList() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      postRequest("selectAllSysRoomStatus", {}).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          this.roomStatusList = res.data;
        } else {
          this.$message({
            message: res.message,
            center: true,
            duration: 5000,
          });
        }
      });
    },
    handleSelectionChange(val) {
      this.chooseList = val;
    },
    rowClick(row, col, event) {
      this.$refs.table.toggleRowSelection(row);
      this.dialogCheck(this.chooseList);
    },
    dialogCheck(selection) {
      // if (selection.length > 1) {
      //   const del_row = selection.shift();
      //   this.$refs.table.toggleRowSelection(del_row, false);
      // }
    },
    rowClicks(row, col, event) {
      // this.chooseList = val;
      this.showSetValue = false;
      console.log(333, row);
      this.theName = row.deviceName;
      this.$refs.multipleTable.toggleRowSelection(row, row.flag);
    },
    handleChoose(val) {
      this.firstList = val;
      this.getJuris(val);
    },
    // 获取配置参数
    getJuris(val) {
      this.canshuSet = [];
      this.other = val[0];
      let length = val[val.length - 1];
      if (length) {
        this.operateOrder = val[val.length - 1].index;
        let parms = {
          deviceType: val[val.length - 1].deviceType,
        };
        postRequest("/getSysDeviceParams", parms).then((res) => {
          if (res.status == 200) {
            var addPart = [
              {
                name: this.deviceTypeName,
                paramName: "触发状态",
                values: [],
                controlDeviceTrigger: "Valid",
              },
              { paramName: "控制模式", values: [], controlledMode: "" },
            ];
            let list1 = addPart.concat(res.data);
            res.data = list1.concat([
              { paramName: "延时", values: [], delayTime: 0 },
            ]);
            let list = res.data;
            list.forEach((item1) => {
              item1.deviceTypeName = val[val.length - 1].deviceTypeName;
              item1.deviceName = val[val.length - 1].deviceName;
              // 如果一样从this.Params内加入到canshuSet(保存之前的数据)
              this.Params.forEach((item3) => {
                if (
                  item1.deviceName == item3.deviceName &&
                  item1.deviceTypeName == item3.deviceTypeName &&
                  item1.paramName == item3.paramName
                ) {
                  this.canshuSet.push(item3);
                }
              });
            });
            console.log(this.canshuSet);
            console.log(this.Params);

            // return
            this.deviceTypeName = list[0].deviceTypeName;
            this.deviceName = this.theName;

            this.canshuSet.forEach((item) => {
              if (item.controlledMode) {
                if (item.controlledMode != "SetValue") {
                  this.showSetValue = false;
                } else {
                  this.showSetValue = true;
                }
              }
            });
            if (!this.showSetValue) {
              let list = [];
              this.canshuSet.forEach((item) => {
                if (item.values.length == 0) {
                  list.push(item);
                }
              });
              this.canshuSet = list;
            }
            console.log(333, this.canshuSet);
            this.canshuSet;
            for (let i = 0; i < this.canshuSet.length - 1; i++) {
              for (let j = i + 1; j < this.canshuSet.length; j++) {
                if (
                  this.canshuSet[i].paramName == this.canshuSet[j].paramName
                ) {
                  this.canshuSet.splice(j, 1);
                  j--;
                }
              }
            }
          } else {
            this.$message({
              message: res.message,
              type: "warning",
              duration: 5000,
            });
          }
        });
      } else {
        this.deviceTypeName = null;
      }
    },
    save() {
      if (this.canshuSet.length == 0) {
        this.$message({
          message: "请为已选设备配置参数！",
          type: "warning",
          duration: 5000,
        });
        return;
      }
      if (this.controlledMode == "Equal" && this.powerTableList.length == 0) {
        this.$message({
          message: "请选择相同与！",
          center: true,
          duration: 5000,
        });
        this.sameVisible = true;
      } else {
        let setList = [];
        this.Params.forEach((item) => {
          this.choosepower.forEach((item1) => {
            if (
              item.deviceName == item1.deviceName &&
              item.deviceTypeName == item1.deviceTypeName &&
              (item.values.length > 0 ||
                item.controlDeviceTrigger != null ||
                item.controlledMode != null ||
                item.delayTime != null)
            ) {
              setList.push(item);
            }
          });
        });

        this.totelSetList = setList;
        this.totelSetList.forEach((item) => {
          if (item.values.length == 1) {
            item.valueDesc = item.valueCode;
          } else {
            item.values.forEach((element) => {
              if (item.valueCode == element.valueCode) {
                item.valueDesc = element.valueDesc;
              }
            });
          }
        });
        let dest1 = [];
        let map = this.totelSetList;
        let list1 = this.totelSetList;
        let list2 = [];
        let list3 = [];
        // return;

        list1.forEach((item) => {
          dest1.push({
            deviceName: item.deviceName,
            deviceType: item.deviceType,
            deviceTypeName: item.deviceTypeName,
          });
        });
        console.log(3333333, dest1);
        //第一次遍历数组
        for (let i = 0; i < dest1.length - 1; i++) {
          for (let j = i + 1; j < dest1.length; j++) {
            if (
              dest1[i].deviceName == dest1[j].deviceName &&
              dest1[i].deviceType == dest1[j].deviceType &&
              dest1[i].deviceTypeName == dest1[j].deviceTypeName
            ) {
              dest1.splice(j, 1);
              j--;
            }
          }
        }
        // dest1 = [...new Set(dest1)];
        console.log(3333333, dest1);

        map.forEach((item1) => {
          if (
            item1.deviceTypeName == this.changeStat.deviceTypeName &&
            item1.deviceName == this.changeStat.deviceName &&
            item1.deviceType == this.changeStat.deviceType
          ) {
            if (item1.controlDeviceTrigger) {
              item1.controlDeviceTrigger = this.changeStat.val;
            }
          }
          dest1.forEach((item2) => {
            console.log(555, this.changeStat);
            if (
              item1.deviceTypeName == item2.deviceTypeName &&
              item1.deviceName == item2.deviceName &&
              item1.deviceType == item2.deviceType
            ) {
              list2.push(item1);
            }
          });
        });

        dest1.forEach((item) => {
          list3.push({
            deviceName: item.deviceName,
            deviceType: item.deviceType,
            deviceTypeName: item.deviceTypeName,
          });
        });
        for (let i = 0; i < list2.length - 1; i++) {
          for (let j = i + 1; j < list2.length; j++) {
            if (
              list2[i].paramName == list2[j].paramName &&
              list2[i].deviceTypeName == list2[j].deviceTypeName &&
              list2[i].deviceName == list2[j].deviceName &&
              list2[i].deviceType == list2[j].deviceType
            ) {
              list2.splice(j, 1);
              j--;
            }
          }
        }
        // console.log(list2);
        // return;
        list3.forEach((item) => {
          item.list = [];
          list2.forEach((item2) => {
            if (
              item.deviceName == item2.deviceName &&
              item.deviceType == item2.deviceType &&
              item.deviceTypeName == item2.deviceTypeName
            ) {
              item.list.push(item2);
            }
          });
        });

        let flag = 0;
        this.canshuSet.forEach((item) => {
          if (item.controlledMode === null) {
            flag = 1;
          }
        });
        if (flag == 1) {
          this.$message({
            duration: 5000,
            message: "请选择控制模式",
            type: "warning",
          });
          return;
        }

        list3.forEach((item1) => {
          let list = [];
          item1.list.forEach((item2) => {
            if (item2.values.length == 0) {
              list.push(item2);
            }
            if (item2.controlledMode) {
              if (item2.controlledMode != "SetValue") {
                this.showSure = 1;
              } else {
                this.showSure = 0;
              }
            }
          });
          if (this.showSure == 1) {
            item1.list = list;
          }
          console.log(1212, item1.list);
        });
        // return;
        list3.forEach((item) => {
          this.sameList.forEach((item2) => {
            console.log(item, item2);
            if (item.deviceName == item2.deviceTypeName2) {
              item.list.forEach((item3) => {
                item3.sameName = item2.deviceName;
              });
            }
          });
        });
        let listBox = [];
        list3.forEach((item) => {
          item.list.forEach((item2) => {
            if (item2.controlledMode) {
              listBox.push(item);
            }
          });
          console.log(222, list3.length);
        });
        // console.log(list3);
        this.dest = list3;
        this.setVisible = true;
      }
    },
    // 最后提交按钮
    setSure() {
      let destFlag = 0;
      let flagMode = 0;
      let setValve = 0;
      let msList = [];
      this.dest.forEach((item) => {
        console.log(3333, item);
        item.list.forEach((item2) => {
          if (item2.paramName == "控制模式") {
            msList.push(item2);
          }
          if (
            item2.controlledMode == "" ||
            item2.controlledMode == null ||
            item2.controlledMode == undefined
          ) {
            setValve = 1;
          } else {
            setValve = 0;
          }
        });
      });
      if (msList.length != this.dest.length) {
        this.$message({
          message: "请为所有已选设备配置控制模式！",
          type: "warning",
          duration: 5000,
        });
        return;
      }
      if (destFlag == 1) {
        this.$message({
          message: "控制模式为相同与请选择正确的相同与数据！",
          type: "warning",
          duration: 5000,
        });
        return;
      }
      let list = [];
      this.totelSetList.forEach((item) => {
        console.log(9998, item);
        list.push({
          deviceName: item.deviceName,
          deviceTypeName: item.deviceTypeName,
          controlDeviceTrigger: item.controlDeviceTrigger,
        });
      });
      // 去重
      for (let i = 0; i < list.length - 1; i++) {
        for (let j = i + 1; j < list.length; j++) {
          if (
            list[i].deviceName == list[j].deviceName &&
            list[i].deviceType == list[j].deviceType &&
            list[i].deviceTypeName == list[j].deviceTypeName
          ) {
            list.splice(j, 1);
            j--;
          }
        }
      }
      list.forEach((item) => {
        this.totelSetList.forEach((item2) => {
          console.log(88, item, item2);
          if (
            item.deviceTypeName == item2.deviceTypeName &&
            item.deviceName == item2.deviceName
          ) {
            if (item2.controlledMode) {
              item.controlMode = item2.controlledMode;
            }
            if (item2.delayTime) {
              item.delayTime = item2.delayTime;
            } else {
              item.delayTime = 0;
            }
          }
        });
      });
      list.forEach((item) => {
        let list2 = [];
        // 相同与
        if (item.controlMode == "Equal") {
          this.sameList.forEach((item2) => {
            console.log("hhhhh", item, item2);
            if (item.deviceName == item2.deviceTypeName2) {
              item.sameDevice = item2.id;
            }
          });
        }
        // 设置值
        if (item.controlMode == "SetValue") {
          this.totelSetList.forEach((item3) => {
            if (
              item.deviceTypeName == item3.deviceTypeName &&
              item3.values.length > 0
            ) {
              console.log(item3);

              let parms = {};
              if (item3.valueCode != null) {
                parms = {
                  paramName: item3.paramName,
                  paramCode: item3.paramCode,
                  valueCode: item3.valueCode,
                  valueDesc: item3.valueDesc, // String  值说明
                };
              }
              if (parms.paramCode != null) {
                list2.push(parms);
              }
              item.paramList = list2;
            }
          });
        }
      });
      let logo = 0;
      list.forEach((item2) => {
        if (item2.controlledMode == "SetValue" && item2.paramList.length == 0) {
          logo = 1;
          this.$message({
            message: "请为" + item2.deviceTypeName + "设置值！",
            type: "warning",
            duration: 5000,
          });
          return;
        }
        item2.controlDevice = this.main.id;
        item2.controlNameId = this.main.deviceNameId;
        item2.roomType = this.main.roomType;
        item2.controlDid = this.main.deviceType;
        this.choosepower.forEach((item3) => {
          if (item3.deviceTypeName == item2.deviceTypeName) {
            item2.controlledDevice = item3.id;
            item2.controlledNameId = item3.deviceNameId;
            item2.controlledDid = item3.deviceType;
            item2.operateOrder = item3.index;
          }
        });
      });
      if (logo == 0) {
        // console.log(1212, list);
        // return;
        if (this.flag2 == 0) {
          this.flag2 = 1;
          let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
          postRequest("insertHotelDeviceControl", list).then((res) => {
            this.$nextTick(() => {
              loadingInstance.close();
            });
            if (res.status == 200) {
              this.$message({
                message: "设置成功！",
                center: true,
                type: "success",
                duration: 5000,
              });
              this.setVisible = false;
              this.showing = false;
            } else {
              this.$message({
                message: res.message,
                center: true,
                duration: 5000,
              });
            }
            this.flag2 = 0;
          });
        }
      }

      // this.totelSetList;
    },
    right() {
      if (this.chooseList.length > 0) {
        this.choosepower = this.choosepower.concat(this.chooseList);
        this.choosepower.forEach((item, index) => {
          item.index = index + 1;
        });
        this.getAllSet(this.choosepower);
        this.mode.forEach((item, index) => {
          this.chooseList.forEach((item1) => {
            if (item.id == item1.id) {
              this.mode.splice(index, this.chooseList.length);
            }
          });
        });
        this.chooseList = [];
        this.deviceTypeName = null;
        console.log(this.choosepower);
      } else {
        this.$message({
          message: "请选择被控设备!",
          type: "warning",
          duration: 5000,
        });
      }
    },
    left() {
      if (this.firstList.length > 0) {
        let listAll = [];
        this.firstList.forEach((listItem) => {
          this.Params.forEach((listItem2) => {
            if (listItem.deviceTypeName != listItem2.deviceTypeName) {
              listAll.push(listItem2);
            }
          });
        });
        this.Params = listAll;
        // console.log(1, listAll);
        // return;
        this.mode = this.mode.concat(this.firstList);
        this.choosepower.forEach((item, index) => {
          this.firstList.forEach((item1) => {
            if (item.id == item1.id) {
              this.choosepower.splice(index, this.firstList.length);
            }
          });
        });
        console.log(1111, this.choosepower);
        this.getAllSet(this.choosepower);
        // localStorage.setItem("set", JSON.stringify(this.choosepower));
        // let set = JSON.parse(window.localStorage.getItem("set"));
        // console.log(set);
        this.firstList = [];
        this.deviceTypeName = null;
      } else {
        this.$message({
          message: "请选择需要移除的设备!",
          type: "warning",
          duration: 5000,
        });
      }

      // console.log(111,this.firstList)
    },
    getAllSet(list) {
      localStorage.setItem("set", JSON.stringify(list));
      let set = JSON.parse(window.localStorage.getItem("set"));
      set.forEach((item) => {
        let parms = {
          deviceType: item.deviceType,
        };
        postRequest("/getSysDeviceParams", parms).then((res) => {
          if (res.status == 200) {
            res.data[0].deviceName = item.deviceName;
            res.data[0].deviceTypeName = item.deviceTypeName;
            var addPart = [
              {
                name: this.deviceTypeName,
                paramName: "触发状态",
                values: [],
                controlDeviceTrigger: "Valid",
              },
              { paramName: "控制模式", values: [], controlledMode: null },
            ];
            let list1 = addPart.concat(res.data);
            res.data = list1.concat([
              { paramName: "延时", values: [], delayTime: 0 },
            ]);
            res.data.forEach((item1) => {
              item1.deviceName = item.deviceName;
              item1.deviceTypeName = item.deviceTypeName;
              item1.deviceType = item.deviceType;
              item1.valueCode = null;
              item1.valueDesc = null;
            });
            this.Params = this.Params.concat(res.data);
            // console.log(22, this.Params);
            // return
            // 去除重复的
            // for (let i = 0; i < this.Params.length - 1; i++) {
            //   for (let j = i + 1; j < this.Params.length; j++) {
            //     if (
            //       this.Params[i].deviceName == this.Params[j].deviceName &&
            //       this.Params[i].deviceTypeName == this.Params[j].deviceTypeName&&
            //         this.Params[i].deviceType == this.Params[j].deviceType
            //     ) {
            //       this.Params.splice(j, 1);
            //       j--;
            //     }
            //   }
            // }
            console.log(444, this.Params);
            this.$store.state.ParamsList = this.Params;
            return;
          } else {
            this.$message({
              message: res.message,
              type: "warning",
              duration: 5000,
            });
          }
        });
      });
    },
    setInput(row) {
      this.Params.forEach((item, index) => {
        if (
          item.deviceName == row.deviceName &&
          item.deviceTypeName == row.deviceTypeName &&
          item.paramCode == row.paramCode
        ) {
          this.Params.splice(index, 1, row);
        }
      });
      console.log(4321, this.Params);
    },
    setSelect(row) {
      this.Params.forEach((item, index) => {
        if (
          item.deviceTypeName == row.deviceTypeName &&
          item.deviceName == row.deviceName &&
          item.paramCode == row.paramCode
        ) {
          this.Params.splice(index, 1, row);
        }
      });
      console.log(4321, this.Params);
    },
    // 切换
    modeChange(row) {
      console.log(row);
      this.controlledMode = row.controlledMode;
      // 设置值
      if (row.controlledMode != "SetValue") {
        console.log(999, this.canshuSet);
        this.showSetValue = false;
      }
      if (row.controlledMode == "SetValue") {
        this.showSetValue = true;
        this.canshuSet = [];
        this.Params.forEach((item) => {
          if (
            row.deviceName == item.deviceName &&
            row.deviceTypeName == item.deviceTypeName &&
            row.deviceType == item.deviceType &&
            item.paramName != "房间温度"
          ) {
            if (item.controlledMode) {
              item.controlledMode = "SetValue";
            }
            this.canshuSet.push(item);
            // console.log(3333, this.canshuSet);
            // return;
            for (let i = 0; i < this.canshuSet.length - 1; i++) {
              for (let j = i + 1; j < this.canshuSet.length; j++) {
                if (
                  this.canshuSet[i].paramName == this.canshuSet[j].paramName &&
                  this.canshuSet[i].deviceType == this.canshuSet[j].deviceType
                ) {
                  this.canshuSet.splice(j, 1);
                  j--;
                }
              }
            }
          }
        });
        this.canshuSet[0].controlDeviceTrigger = this.setvalue;
      }
      if (row.controlledMode == "Equal") {
        this.sameVisible = true;
        this.$refs.powerTable.clearSelection();
      }
      // return
      if (!this.showSetValue) {
        let list = [];
        this.canshuSet.forEach((item) => {
          if (item.values.length == 0) {
            list.push(item);
          }
        });

        this.canshuSet = list;
      }
    },
    closeSame() {
      console.log(this.powerTableList.length);
      if (this.powerTableList.length == 0) {
        this.sameVisible = true;
        this.$message({
          message: "请选择相同与！",
          center: true,
          duration: 5000,
        });
        return;
      } else {
        this.sameVisible = false;
      }
    },
    handleChoosepower(val) {
      // deviceTypeName
      this.powerTableList = val;
    },
    //
    rowClick2(row, col, event) {
      console.log(row);
      this.$refs.powerTable.toggleRowSelection(row);
      this.dialogPower(this.powerTableList);
      row.deviceTypeName2 = this.deviceName;
      if (this.sameList.length == 0) {
        this.sameList.push(row);
        console.log(this.sameList);
      } else {
        this.sameList.forEach((item, index) => {
          console.log(item.deviceName);
          if (
            item.deviceTypeName2 == row.deviceTypeName2 &&
            item.id != row.id
          ) {
            // this.sameList.push(row);
            this.sameList.splice(index, 1, row);
          } else {
            this.sameList.push(row);
          }
        });

        console.log(this.sameList);
      }
    },
    dialogPower(selection) {
      if (selection.length > 1) {
        // this.$refs.powerTable.clearSelection();
        const del_row = selection.shift();
        this.$refs.powerTable.toggleRowSelection(del_row, false);
      }
    },
    // 相同与情况下
    sameSetSure() {
      if (this.powerTableList.length < 1) {
        this.$message({
          message: "请选择相同与设备！",
          type: "warning",
          duration: 5000,
        });
      } else {
        this.sameVisible = false;
        // this.sameDevice = this.powerTableList[0].id;
        // this.setSure("相同与");
        // this.save();
      }
    },
    handleChang(val, item) {
      console.log(val, item);
      this.setvalue = val;
      // this.canshuSet;
      this.changeStat = {
        deviceTypeName: item.deviceTypeName,
        deviceName: item.deviceName,
        deviceType: item.deviceType,
        val: val,
      };
    },
  },
};
</script>

<style scoped>
.el-form-item__label {
  display: block !important;
  width: 100% !important;
}
.el-radio-group {
  width: 100%;
}
.el-button {
  width: 80px;
  height: 40px;
  background: #387dff;
  border-radius: 4px;
  margin-top: 32px;
  text-align: center;
  margin-left: 0 !important;
}
.sure {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #d8d8d8;
}
.banben {
  display: flex;
  justify-content: space-between;
}
.el-button:hover {
  transform: scale(1.1);
}
.big_box {
  display: flex;
  justify-content: space-between;
  height: 450px;
}
.left_box {
  width: 30%;
  height: 100%;
  background: #ffffff;
  border: 1px solid #e9e9e9;
  border-radius: 4px 4px 0px 0px;
  overflow: hidden;
  overflow-y: scroll;
  white-space: nowrap;
  position: relative;
}
.left_box ::-webkit-scrollbar {
  width: 0 !important;
}

.left_box::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}
.right_box ::-webkit-scrollbar {
  width: 0 !important;
}

.right_box::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}
.right_box {
  width: 35%;
  height: 100%;
  background: #ffffff;
  border: 1px solid #e9e9e9;
  border-radius: 4px 4px 0px 0px;
  overflow: hidden;
  overflow-y: scroll;
  white-space: nowrap;
  position: relative;
}
.set_box {
  width: 25%;
  height: 100%;
  border: 1px solid #e9e9e9;
  border-radius: 4px 4px 0px 0px;
  overflow-x: hidden;
  overflow-y: scroll;

  position: relative;
}
.header_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 11px 16px; */
  padding: 0 10px;
  position: absolute;
  width: 100%;
  height: 44px;
  background: #fafafa;
  border-bottom: 1px solid #e9e9e9;
  border-right: 1px solid #e9e9e9;
  border-radius: 3px 3px 0px 0px;
  /* position: fixed; */
  z-index: 1000;
}
.jurisdiction {
  /* width: 64px; */
  height: 22px;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.85);
}
.select {
  width: 28px;
  height: 20px;
  font-size: 12px;
  font-weight: 500;
  line-height: 22px;
  color: #387dff;
  cursor: pointer;
}
.select:hover {
  transform: scale(1.1);
}
.bottom {
  margin-left: 16px;
  margin-top: 13px;
  display: flex;
  flex-direction: column;
}
.el-checkbox + .el-checkbox {
  margin-left: 0 !important;
}
.Horizontal {
  width: 97%;
  height: 1px;
  background: #e8ecef;
  border-radius: 0px;
  margin: 0 auto;
  margin-top: 32px;
}
.content {
  margin-top: 50px;
}
.spname {
  /* margin-left: 10px; */
  cursor: pointer;
  display: inline-block;
}
.change {
  width: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.img {
  height: 30px;
  margin-bottom: 10px;
  width: 50px;
  cursor: pointer;
}
.el-radio-group {
  display: flex;
  flex-direction: column;
}
>>> .el-table__header-wrapper .el-checkbox {
  display: none;
}
.saveBox {
  position: relative;
  height: 50px;
}
.addIdType {
  position: absolute;
  right: 0;
}
.setSave {
  position: absolute;
  right: 0;
  bottom: 10px;
}
>>> .setSure {
  z-index: 99999 !important;
}
.el-dialog__body {
  position: relative;
}
.title {
  font-size: 20px;
  font-weight: bold;
  margin-top: 20px;
}
.setFalse {
  color: #387dff;
  background: #d7e4fc;
  margin-right: 10px;
}
.paramName {
  display: flex;
  flex-direction: column;
}
.paramName > div {
  margin-right: 20px;
  display: flex;
}
.el-radio-group {
  display: flex;
  flex-direction: row;
}
.el-radio {
  width: 50%;
}
.leftName {
  width: 70px;
  text-align: right;
  margin-right: 10px;
}
</style>